import React from 'react';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import EyeLogo from '../components/eye-logo';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--space-xl) var(--space-s);

  h1 {
    font-size: clamp(5.6rem, 18vw, 10rem);
    line-height: 1;
    text-align: center;
    margin: var(--space-l) 0;

    @media (${bp.min.xs}) {
      margin: var(--space-xl) 0;
    }

    @media (${bp.min.md}) {
      margin: var(--space-xxl) 0;
    }
  }
`;

const NotFoundPage = () => (
  <Layout is404>
    <SEO title="404: Not found" />

    <Container>
      <EyeLogo is404 />
      <h1>Nothing to see here</h1>
      <Button text="Back to homepage" url="/" withArrow reversed />
    </Container>
  </Layout>
);

export default NotFoundPage;
